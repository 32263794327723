<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit">
      <div slot="content">
        <a-card title="年卡价格">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="卡名称" prop="yearCardName">
                  <a-input v-model="form.yearCardName" :maxLength="50" :disabled="true"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="价格" prop="price">
                  <a-row :gutter="16">
                    <a-col :span="8">
                      <a-input-number style="width: 100%" size="large" v-model="form.price" :min="0" step="0.01" :max="9999999999" :disabled="isDetail"></a-input-number>
                    </a-col>
                    <a-col :span="4">RM</a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { yearEdit, yearInfo } from '@/api/year';
import FormFrame from '@/components/frame/FormFrame.vue';
import formMixin from '@/mixin/formMixin';

export default {
  name: 'VipForm',
  mixins: [formMixin],
  components: {
    FormFrame,
  },
  data() {
    return {
      form: {
        yearCardName: '',
        price: 0,
      },
      rules: {
        price: [
          {
            required: true,
            message: '请输入价格',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
  },
  methods: {
    // 获取信息
    getData(id) {
      yearInfo(id).then((data) => {
        this.form = data;
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
          };
          console.log(params);
          yearEdit(params).then((res) => {
            if (res) {
              this.$message.success(res.message || '操作成功');
              this.handleBack();
            } else {
              this.$message.error('操作失败');
              return false;
            }
          });
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
